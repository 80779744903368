import React from "react";
import { PageProps } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import SEO from "../../components/seo";

const EventsPage: React.FC<PageProps> = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const { t: t2 } = useTranslation("navigation");
  const name = t("seththee_name");
  const description = t("seththee_description", { name });
  const title = `${t("hotel")} ${name} | ${t2("events")}`;
  return (
    <main className="full-centered">
      <SEO pageTitle={title} language={language} description={description} />
      <h1>{t2("events")}</h1>
      <p>Big meeting, wedding celebration</p>
    </main>
  );
};

export default EventsPage;
