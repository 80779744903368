import React from "react";
import { Helmet } from "gatsby-plugin-react-i18next";

type SEOProps = {
  pageTitle: string; //<=60 characters including spaces and punctuation (>=50 ideal),
  // If you use more than one keyword or include your brand, separate them using pipes (the | character),<title>Smith & Sons | Construction | Toledo</title>
  language: string;
  description: string; //<=160 characters, keywords matching search queries displayed in bold.
  image?: MetaImage;
  meta?: ReadonlyArray<TwitterCard | OGObject>;
};

type MetaImage = {
  src: string;
  width: number;
  height: number;
};

type MetaName<T extends string, U extends string = string> = {
  name: T;
  content: U;
};

type MetaProperty<T extends string, U extends string = string> = {
  property: T;
  content: U;
};

type TwitterCardSummary = {
  card: MetaName<"twitter:card", "summary">;
  site: MetaName<"site", string> | MetaName<"site:id">; // @username of website. Either twitter:site or twitter:site:id (Same as twitter:site, but the user’s Twitter ID) is required.
  creator?: MetaName<"twitter:creator:id">; // Twitter user ID of content creator
  description: MetaName<"twitter:description">; //Description of content (maximum 200 characters), iOS, Android: Not displayed, Web: Truncated to three lines in timeline and expanded Tweet
  title: MetaName<"twitter:title">; // Title of content (max 70 characters), iOS, Android: Truncated to two lines in timeline and expanded Tweet, Web: Truncated to one line in timeline and expanded Tweet
  image: MetaName<"twitter:image">; //A URL to a unique image representing the content of the page. You should not use a generic image such as your website logo, author photo, or other image that spans multiple pages. Images for this Card support an aspect ratio of 1:1 with minimum dimensions of 144x144 or maximum of 4096x4096 pixels. Images must be less than 5MB in size. The image will be cropped to a square on all platforms. JPG, PNG, WEBP and GIF formats are supported. Only the first frame of an animated GIF will be used. SVG is not supported.
  imageAlt: MetaName<"twitter:image:alt">; //A text description of the image conveying the essential nature of an image to users who are visually impaired. Maximum 420 characters.
};

type TwitterCardSummaryLargeImage = {
  card: MetaName<"card", "summary_large_image">;
  site: MetaName<"site", string> | MetaName<"site:id">; // @username of website. Either twitter:site or twitter:site:id (The Twitter @username the card should be attributed to) is required.
  creator?: MetaName<"twitter:creator"> | MetaName<"twitter:creator:id">; // @username of content creator / Twitter user ID of content creator
  title: MetaName<"twitter:title">; // Title of content (max 70 characters), iOS, Android: Truncated to two lines in timeline and expanded Tweet, Web: Truncated to one line in timeline and expanded Tweet
  description: MetaName<"twitter:description">; // Description of content (maximum 200 characters), iOS, Android: Not displayed, Web: Truncated to three lines in timeline and expanded Tweet
  image: MetaName<"twitter:image">; // A URL to a unique image representing the content of the page. You should not use a generic image such as your website logo, author photo, or other image that spans multiple pages. Images for this Card support an aspect ratio of 1:1 with minimum dimensions of 144x144 or maximum of 4096x4096 pixels. Images must be less than 5MB in size. The image will be cropped to a square on all platforms. JPG, PNG, WEBP and GIF formats are supported. Only the first frame of an animated GIF will be used. SVG is not supported.
  imageAlt: MetaName<"twitter:image:alt">; // A text description of the image conveying the essential nature of an image to users who are visually impaired. Maximum 420 characters.
};

type TwitterCardPlayer = {
  card: MetaName<"card", "player">;
  site: MetaName<"site"> | MetaName<"site:id">; // @username of website. Either twitter:site or twitter:site:id (The Twitter @username the card should be attributed to) is required.
  description?: MetaName<"twitter:description">; // Description of content (maximum 200 characters), iOS, Android: Not displayed, Web: Truncated to three lines in timeline and expanded Tweet
  title?: MetaName<"twitter:title">; // Title of content (max 70 characters), iOS, Android: Truncated to two lines in timeline and expanded Tweet, Web: Truncated to one line in timeline and expanded Tweet
  image?: MetaName<"twitter:image">; // A URL to a unique image representing the content of the page. You should not use a generic image such as your website logo, author photo, or other image that spans multiple pages. Images for this Card support an aspect ratio of 1:1 with minimum dimensions of 144x144 or maximum of 4096x4096 pixels. Images must be less than 5MB in size. The image will be cropped to a square on all platforms. JPG, PNG, WEBP and GIF formats are supported. Only the first frame of an animated GIF will be used. SVG is not supported.
  imageAlt?: MetaName<"twitter:image:alt">; // A text description of the image conveying the essential nature of an image to users who are visually impaired. Maximum 420 characters.
  player: MetaName<"twitter:player">; // HTTPS URL of player iframe
  playerWidth: MetaName<"twitter:player:width">; // Width of iframe in pixels
  playerHeight: MetaName<"twitter:player:height">; // Height of iframe in pixels
  playerStream: MetaName<"twitter:player:stream">; // URL to raw video or audio stream
};

type TwitterCardApp = {
  card: MetaName<"card", "app">;
  site: MetaName<"site">; // @username of website. Either twitter:site or twitter:site:id (The Twitter @username the card should be attributed to) is required.
  iphoneName?: MetaName<"app:name:iphone">; // Name of your iPhone app
  iphoneId?: MetaName<"app:id:iphone">; // Your app ID in the iTunes App Store (Note: NOT your bundle ID)
  iphoneUrl?: MetaName<"app:url:iphone">; // Your app’s custom URL scheme (you must include ”://” after your scheme name)
  ipadName?: MetaName<"app:name:ipad">; // Name of your iPad optimized app
  ipadId?: MetaName<"app:id:ipad">; // Your app ID in the iTunes App Store
  ipadUrl?: MetaName<"app:url:ipad">; // Your app’s custom URL scheme
  googleplayName?: MetaName<"app:name:googleplay">; // Name of your Android app
  googleplayId?: MetaName<"app:id:googleplay">; // Your app ID in the Google Play Store
  googleplayUrl?: MetaName<"app:url:googleplay">; // Your app’s custom URL scheme
};

type TwitterCard =
  | TwitterCardSummary
  | TwitterCardSummaryLargeImage
  | TwitterCardPlayer
  | TwitterCardApp;
// https://developer.twitter.com/en/docs/twitter-for-websites/cards/overview/markup
/* “summary”, “summary_large_image”, “app”, or “player”, https://developer.twitter.com/en/docs/twitter-for-websites/cards/guides/getting-started*/

// https://ogp.me/
type auto = "auto";
type DateTime = string;
type integer = string;

type OGImage = {
  alt: MetaProperty<"og:image:alt">; // A description of what is in the image (not a caption). If the page specifies an og:image it should specify og:image:alt.
  url: MetaProperty<"og:image:url">; //Identical to og:image.
  secure_url?: MetaProperty<"og:image:secure_url">; // An alternate url to use if the webpage requires HTTPS.
  type?: MetaProperty<"og:image:type", "image/png" | "image/jpeg">; // A MIME type for this image.
  width?: MetaProperty<"og:image:width", integer>; // The number of pixels wide.
  height?: MetaProperty<"og:image:height", integer>; // The number of pixels high.
};

type OGVideo = {
  url: MetaProperty<"og:video">; // <meta property="og:video" content="https://example.com/movie.swf" />
  secure_url?: MetaProperty<"og:video:secure_url">; // <meta property="og:video:secure_url" content="https://secure.example.com/movie.swf" />
  type?: MetaProperty<"og:video:type">; // <meta property="og:video:type" content="application/x-shockwave-flash" />
  width?: MetaProperty<"og:video:width", integer>; // <meta property="og:video:width" content="400" />
  height?: MetaProperty<"og:video:height", integer>; // <meta property="og:video:height" content="300" />
};

type OGAudio = {
  url: MetaProperty<"og:audio">; // <meta property="og:audio" content="https://example.com/sound.mp3" />
  secure_url?: MetaProperty<"og:audio:secure_url">; // <meta property="og:audio:secure_url" content="https://secure.example.com/sound.mp3" />
  type?: MetaProperty<"og:audio:type">; // <meta property="og:audio:type" content="audio/mpeg" />
};

type OGBase = {
  title: MetaProperty<"og:title">; // The title of your object as it should appear within the graph, e.g., "The Rock".
  image: ReadonlyArray<MetaProperty<"og:image"> | OGImage>; // An image URL which should represent your object within the graph.
  url: MetaProperty<"og:url">; // The canonical URL of your object that will be used as its permanent ID in the graph, e.g., "https://www.imdb.com/title/tt0117500/".
  audio?: ReadonlyArray<OGAudio>; // A URL to an audio file to accompany this object.
  description?: MetaProperty<"og:description">; // A one to two sentence description of your object.
  determiner?: MetaProperty<"og:determiner", "a" | "an" | "the" | "" | auto>; // The word that appears before this object's title in a sentence. An enum of (a, an, the, "", auto). If auto is chosen, the consumer of your data should chose between "a" or "an". Default is "" (blank).
  locale?: MetaProperty<"og:locale">; // The locale these tags are marked up in. Of the format language_TERRITORY. Default is en_US.
  alternate?: ReadonlyArray<MetaProperty<"og:alternate">>; // An array of other locales this page is available in.
  site_name?: MetaProperty<"og:site_name">; //If your object is part of a larger web site, the name which should be displayed for the overall site. e.g., "IMDb".
  video?: ReadonlyArray<OGVideo>; // A URL to a video file that complements this object.
  facebookAppId?: MetaProperty<"fb:app_id">; // used for Facebook-Insights(https://developers.facebook.com/docs/sharing/referral-insights), see App Dashboard(https://developers.facebook.com/apps/redirect/dashboard).
};

type OGWebsite = OGBase & {
  type: MetaProperty<"og:type", "website">;
};

type OGArticle = OGBase & {
  type: MetaProperty<"og:type", "article">;
  // <head prefix="article: https://ogp.me/ns/article#">
  published_time?: MetaProperty<"article:published_time", DateTime>; // When the article was first published.
  modified_time?: MetaProperty<"article:modified_time", DateTime>; // When the article was last changed.
  expiration_time?: MetaProperty<"article:expiration_time", DateTime>; // When the article is out of date after.
  author?: ReadonlyArray<MetaProperty<"article:author", string> & OGProfile>; //profile array - Writers of the article.
  section?: MetaProperty<"article:section", string>; // A high-level section name. E.g. Technology
  tag?: ReadonlyArray<MetaProperty<"article:tag", string>>; // Tag words associated with this article.};
};

type OGBook = OGBase & {
  type: MetaProperty<"og:type", "book">;
  // <head prefix="book: https://ogp.me/ns/book#">
  author?: ReadonlyArray<MetaProperty<"book:author", string> & OGProfile>; //Who wrote this book.
  release_date?: MetaProperty<"book:release_date", DateTime>; // The date the book was released.
  isbn?: MetaProperty<"book:release_date", string>; // The ISBN
  tag?: ReadonlyArray<MetaProperty<"book:tag", string>>; // Tag words associated with this article.};
};

type OGProfile = OGBase & {
  type: MetaProperty<"og:type", "profile">;
  // <head prefix="profile: https://ogp.me/ns/profile#">
  first_name: MetaProperty<"profile:first_name">; //  A name normally given to an individual by a parent or self-chosen.
  last_name: MetaProperty<"profile:last_name">; // A name inherited from a family or marriage and by which the individual is commonly known.
  username: MetaProperty<"profile:username">; // A short unique string to identify them.
  gender: MetaProperty<"profile:gender", "male" | "female">; // Their gender.
};

type OGObject = OGWebsite | OGArticle | OGBook | OGProfile | Music | Video;

type MusicSong = OGBase & {
  // <head prefix="music: https://ogp.me/ns/music#">
  type: MetaProperty<"og:type", "music.song">;
  duration: MetaProperty<"music:duration", string>; // integer >=1 - The song's length in seconds.
  album: ReadonlyArray<MetaProperty<"music:album", string> & MusicAlbum>; // music.album array - The album this song is from.
  albumDisc: MetaProperty<"music.album.disc", integer>; // integer >=1 - Which disc of the album this song is on.
  albumTrack: MetaProperty<"music.album.track", integer>; // integer >=1 - Which track this song is.
  musician: ReadonlyArray<MetaProperty<"music:musician", string> & OGProfile>; // The musicians that made this song.
};

type MusicAlbum = OGBase & {
  // <head prefix="music: https://ogp.me/ns/music#">
  type: MetaProperty<"og:type", "music.album">;
  song: MetaProperty<"music:song", string> & MusicSong; // The song on this album.
  songDisc: MetaProperty<"music:song:disc", integer>; // integer >=1 - The same as music:album:disc but in reverse.
  songTrack: MetaProperty<"music:song:track", integer>; // integer >=1 - The same as music:album:track but in reverse.
  musician: MetaProperty<"music:musician", string> & OGProfile; // The musician that made this song.
  release_date: MetaProperty<"music:release_date", DateTime>; //The date the album was released.
};

type MusicPaylist = OGBase & {
  // <head prefix="music: https://ogp.me/ns/music#">
  type: MetaProperty<"og:type", "music.playlist">;
  creator?: MetaProperty<"music:creator", string> & OGProfile; // The musician that made this song.
};

type MusicRadioStation = OGBase & {
  // <head prefix="music: https://ogp.me/ns/music#">
  type: MetaProperty<"og:type", "music.radio_station">;
  creator?: MetaProperty<"music:creator", string> & OGProfile; // The musician that made this song.
};

type Music = MusicSong | MusicAlbum | MusicPaylist | MusicRadioStation;

type VideoMovie = OGBase & {
  // <head prefix="video: https://ogp.me/ns/video#">
  type: MetaProperty<"og:type", "video.movie">;
  actor: ReadonlyArray<MetaProperty<"video.actor"> & OGProfile>; //Actors in the movie.
  director: ReadonlyArray<MetaProperty<"video.director"> & OGProfile>; // Directors of the movie.
  writer: ReadonlyArray<MetaProperty<"video:writer">>; // Writers of the movie.
  duration: MetaProperty<"video:duration", integer>; // integer >=1 - The movie's length in seconds.
  release_date: MetaProperty<"video:release_date">; // The date the movie was released.
  tag?: ReadonlyArray<MetaProperty<"video:tag">>; // Tag words associated with this movie.
};

type VideoEpisode = VideoMovie & {
  // <head prefix="video: https://ogp.me/ns/video#">
  type: MetaProperty<"og:type", "video.episode">;
  series: MetaProperty<"video:series", string> & VideoTVShow; //Which series this episode belongs to.
};

type VideoTVShow = VideoMovie & {
  // <head prefix="video: https://ogp.me/ns/video#">
  type: MetaProperty<"og:type", "video.tv_show">;
};

type VideoOther = VideoMovie & {
  // <head prefix="video: https://ogp.me/ns/video#">
  type: MetaProperty<"og:type", "video.other">;
};

type Video = VideoMovie | VideoEpisode | VideoTVShow | VideoOther;

const SEO: React.FC<SEOProps> = ({
  pageTitle,
  language,
  description,
  image,
  meta,
}) => {
  if (pageTitle.length > 60) {
    pageTitle = `${pageTitle.slice(0, 58)}…`;
  }
  if (description.length > 160) {
    description = `${description.slice(0, 158)}…`;
  }
  return (
    <Helmet title={pageTitle}>
      {/* <html prefix="og: http://ogp.me/ns#"></html> //https://developer.twitter.com/en/docs/twitter-for-websites/cards/guides/getting-started */}
      <title itemProp="name" lang={language}>
        {pageTitle}
      </title>
      <meta
        name="robots"
        content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
      />
      <meta name="description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="th" />
      {/* <meta property="og::alternate" content="en" />
      <meta property="og::alternate" content="de" /> */}
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={description} />
      {/* <meta property="og:url" content={description} />
      <meta property="og:site_name" content={description} />
      <meta property="og:image" content="http://..." />
      <meta property="og:image:url" content="http://..." />
      <meta property="og:image:secure_url" content="https://..." />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:alt" content="Seth Thee Residence front view" />
      <meta property="og:image:width" content="1536" />
      <meta property="og:image:height" content="257" /> */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:description" content={description} />
      <script type="application/ld+json">
        {`
        {
          "@context": "http://schema.org",
          "@type" : "LocalBusiness",
          "name" : "Seth Thee Residence",
          "image" : "https://static.seth-thee.com/images/slides/Room31_1024.jpg",
          "telephone" : "+66-88-8914937",
          "email" : "booking@seth-thee.com",
          "address" : {
            "@type" : "PostalAddress",
            "streetAddress" : "133/3 Charoen Suk Rd.",
            "addressLocality" : "Phon",
            "addressRegion" : "Khon Kaen",
            "addressCountry" : "Thailand",
            "postalCode" : "40120"
          },
          "url" : "https://seth-thee.com/",
          "aggregateRating" : {
            "@type" : "AggregateRating",
            "ratingValue" : "4.2",
            "ratingCount" : "220"
          },
          "priceRange": "฿500-฿1100"
        }
        `}
      </script>
    </Helmet>
  );
};

export default SEO;
